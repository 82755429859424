<template>
    <el-pagination
            v-if="paginationData.total > 0"
            :page-size="paginationData.size"
            :current-page.sync="page"
            :pager-count="11"
            layout="prev, pager, next"
            :total="paginationData.total"
            @current-change="changePage('page', page)"
            class="pagination">
    </el-pagination>
</template>
<script>
    import {mapGetters, mapState} from "vuex";
    import EventBus from "@/utils/event-bus";
    export default {
        name: 'BoardCommentPaginationtDefaultLayout',
        // inject: ['commentSetPage', 'commentSetFilter'],
        inject: {
            commentSetPage:{
                default: () => {},
            },
            commentSetFilter: {
                default: () => {},
            },
        },
        props: {
            paginationData: {
                type: Object,
                default: {
                    total: 0,
                    page: 0,
                    size: 0,
                }
            },
            listLength: {
                default: 0
            },
        },
        data() {
            return {
                page: 1,

            }
        },
        computed: {

        },
        mounted() {

        },
        methods: {
            changePage(key, value) {
                this.commentSetPage(key, value);
            },

        },
        watch: {
            'paginationData.page': {
                deep: true,
                handler: function (val, oldVal) {
                    this.page = this.paginationData.page;
                }
            },
        },
    }
</script>